import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import CircledMouse from '../CircledMouse/CircledMouse';
import './Projects.css';
import SwiftApp from '../../assets/backgroundSwiftApp.png';
import SwiftMobile from '../../assets/swiftAppMobile.png';
import EthereumLogo from '../../assets/ethereumLogo.png';
import AlloyCare from '../../assets/alloycare.png';
import NetflixLogo from '../../assets/netflixLogo.png'; 
import AirCnc from '../../assets/aircnc.png'; 
import FoodyImg from '../../assets/foody.png'; 
import CinemaPic from '../../assets/cinemaPic.png';
import CurrencyPic from '../../assets/currencyPic.png';
import SnackPic from '../../assets/snackApp.png';
import CrudPic from '../../assets/crudspringboot.png';
import GitHubIcon from '@mui/icons-material/GitHub';

const Project = () => {
    return (
        <>
        <CircledMouse/>
        <Header />

        <div className='firstProject'>
            <img className='currencyImg' src={CrudPic} alt='An insomnia query for a post on a user structure'/>
            <div>
                <p className='appTitle'>Java Spring Boot CRUD</p>
                <p className='appText'>Manage users effortlessly, powered by the <span className='boldy'>speed and simplicity</span> of Spring Boot and JPA 📝<br/></p>
                <div className='githubContainer'>
                    <GitHubIcon className='githubIcon' onClick={() => window.open('https://github.com/GustavoLSantos/JavaSpringBootCrud')}/>
                </div>
            </div>
        </div>

        <div className='snackAppProject'>
            <img className='currencyImg' src={SnackPic} alt='An iphone 15 showcasing snack app on mutiple screens'/>
            <div>
                <p className='appTitle'>Snack App</p>
                <p className='CurrencyConverterText'>Browse <span className='boldyBlue'>snacks</span>, add to cart, all with SwiftUI magic. 🍿📱<br/></p>
                <div className='githubContainer'>
                    <GitHubIcon className='githubIcon' onClick={() => window.open('https://github.com/GustavoLSantos/SnackApp')}/>
                </div>
            </div>
        </div>

        <div className='MovieProject'>
            <div>
                <p className='appTitle'>Movie Booking</p>
                <p className='appText'>An iOS app built with SwiftUI that lets users browse a <span className='boldy'>movie catalog</span>, pick a cinema session, select seats, and book a reservation for their desired date and time.<br/></p>
                <div className='githubContainer'>
                    <GitHubIcon className='githubIcon' onClick={() => window.open('https://github.com/GustavoLSantos/MovieBooking')}/>
                </div>
            </div>
            <img className='foodyImg' src={CinemaPic} alt='a phone with multiple screens coming out of it showing the cinema app'/>
        </div>


        <div className='currencyConverterProject'>
            <img className='currencyImg' src={CurrencyPic} alt='An iphone 15 showcasing foody app on top of a white rock'/>
            <div>
                <p className='appTitle'>Currency Converter</p>
                <p className='CurrencyConverterText'>An iOS app developed in SwiftUI that allows users to  <span className='boldy'>convert </span> monetary values through a public API<br/></p>
                <div className='githubContainer'>
                    <GitHubIcon className='githubIcon' onClick={() => window.open('https://github.com/GustavoLSantos/CurrencyConverter')}/>
                </div>
            </div>
        </div>

        <div className='foodyProject'>
            <div>
                <p className='appTitle'>Foody</p>
                <p className='appText'>Welcome to <span className='boldy'>Foody</span> , your ultimate culinary companion!<br/></p>
                <div className='githubContainer'>
                    <GitHubIcon className='githubIcon' onClick={() => window.open('https://github.com/GustavoLSantos/Foody')}/>
                </div>
            </div>
            <img className='foodyImg' src={FoodyImg} alt='An iphone 15 showcasing foody app on top of a white rock'/>
        </div>

        <div className='firstProject'>
            <img className='cityBg' src={SwiftApp} alt='City'/>
            <div>
                <p className='appTitle'>Weather App</p>
                <p className='appText'>With <span className='boldy'>real-time</span> data fetched from trusted sources, <br/>
                Weather App provides accurate forecasts at your fingertips.</p>
                <div className='githubContainer'>
                    <GitHubIcon className='githubIcon' onClick={() => window.open('https://github.com/GustavoLSantos/WeatherDemo')}/>
                </div>
            </div>
            <img className='mobileBg' src={SwiftMobile} alt='Mobile showing weather app'/>
        </div>

        <div className='secondProject'>
            <img className='ethereumLogo' src={EthereumLogo} alt='Ethereum Logo'/>
            <div>
                <p className='appTitle'>Ethereum Testament</p>
                <p className='appText'>is a pioneering <span className='boldy'>smart contract</span> solution revolutionizing digital<br/>
                inheritance and asset distribution on the <span className='boldy'>Ethereum blockchain.</span></p>
                <div className='githubContainer'>
                    <GitHubIcon className='githubIcon' onClick={() => window.open('https://github.com/GustavoLSantos/Ethereum-Testament')}/>
                </div>
            </div>
        </div>

        <div className='thirdProject'>
            <div>
                <p className='appTitleAlloy'>Alloy Care</p>
                <p className='appText'>is a groundbreaking approach to <span className='boldyBlue'>accelerate breast<br/> cancer diagnosis</span>, spearheading innovation in the field. </p>
                <div className='githubContainer'>
                    <GitHubIcon className='githubIcon' onClick={() => window.open('https://github.com/GustavoLSantos/AlloyCare')}/>
                </div>
            </div>
            <img className='AlloyCare' src={AlloyCare} alt='AlloyCare'/>
        </div>

        <div className='fourthProject'>
            <img className='NetflixLogo' src={NetflixLogo} alt='NetflixLogo'/>
            <div>
                <p className='appTitleNetflix'>Netflix Clone</p>
                <p className='appText'>inspired by the renowned streaming service Netflix, <br/>this project aimed to <span className='boldyBlue'>recreate</span> its user interface and functionality</p>
                <div className='githubContainer'>
                    <GitHubIcon className='githubIcon' onClick={() => window.open('https://github.com/GustavoLSantos/CloneNetflix')}/>
            </div>
            </div>
        </div>

        <div className='fifthProject'>
            <img className='AirCnc' src={AirCnc} alt='AirCnc'/>
            <div>
                <p className='appTitleAirCnc'>AirCNC</p>
                <p className='appText'>is a platform <span className='boldy'>connecting</span> companies with available spaces<br/> to <span className='boldy'>developers</span> seeking collaborative environments.</p>
                <div className='githubContainer'>
                    <GitHubIcon className='githubIcon' onClick={() => window.open('https://github.com/GustavoLSantos/AirCnC')}/>
            </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default Project;